import React from 'react';
import styled from 'styled-components';
import Pagination from '../Pagination';
import { sectionMargins, minBreakpointQuery } from '../../styles';
import { BodyLarge, H1 } from '../typography/Typography';
import { Container, AnimatedWords } from '../ui';
import CocktailCard from '../CocktailCard/CocktailCard';

const StyledBlogListing = styled.section``;

const StyledHeader = styled.header`
  ${sectionMargins('60px', '160px')};

  max-width: 460px;
`;

const StyledHeading = styled(H1)`
  margin-bottom: 40px;

  ${minBreakpointQuery.small`
          margin-bottom: 50px;
        `}

  ${minBreakpointQuery.large`
          margin-bottom: 60px;
        `}
`;

const StyledItems = styled.div`
  display: grid;
  row-gap: 32px;
  column-gap: 1rem;
  ${sectionMargins(undefined, '200px')};

  ${minBreakpointQuery.mlarge`
    grid-template-columns: repeat(2, 1fr);
    row-gap: 160px;
  `}
`;

const StyledFooter = styled.footer`
  ${sectionMargins('60px', '120px')};
`;

export const CocktailListing = ({ heading, items, meta, introduction }) => {
  console.log(meta);
  return (
    <StyledBlogListing>
      <Container>
        <StyledHeader>
          <StyledHeading currentPage={meta.currentPage}>
            <AnimatedWords>{heading}</AnimatedWords>
          </StyledHeading>
          <BodyLarge>{introduction}</BodyLarge>
        </StyledHeader>
        {items.length > 0 && (
          <StyledItems>
            {items.map(item => (
              <CocktailCard image={item.cardImage} {...item} />
            ))}
          </StyledItems>
        )}
        <StyledFooter>
          <Pagination {...meta} id="as" />
        </StyledFooter>
      </Container>
    </StyledBlogListing>
  );
};
