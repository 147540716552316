import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { CocktailListing } from '../components/CocktailListing/CocktailListing';

const CocktailArchiveTemplate = ({
  data: {
    datoCmsCocktailArchive: { seoMetaTags, title, introduction },
    allDatoCmsCocktailRecipe: { nodes },
  },
  pageContext,
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <CocktailListing
        heading={title}
        items={nodes}
        meta={pageContext}
        introduction={introduction}
      />
    </main>
  </Layout>
);

export const CocktailArchiveTemplateQuery = graphql`
  query CocktailArchiveTemplateQuery($limit: Int!, $skip: Int!) {
    datoCmsCocktailArchive {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      introduction
    }
    allDatoCmsCocktailRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...CocktailCardFragment
      }
    }
  }
`;

export default CocktailArchiveTemplate;
