import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { standardTransition, delays, minBreakpointQuery } from '../../styles';
import { BodyLarge, Eyebrow, H4 } from '../typography/Typography';

// moved up for hover
const StyledImage = styled(GatsbyImage)`
  transition: ${standardTransition('transform')};
`;

const StyledCocktailCard = styled.article`
  cursor: pointer;
  animation: ${standardTransition('fadeUp', undefined, undefined, delays.long)}
    both;

  display: flex;

  &:hover {
    ${StyledImage} {
      transform: scale(1.15);
    }
  }
`;

const StyledImageWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  flex: 1;

  ${minBreakpointQuery.mlarge`
     flex: 1.2;
  `}
`;

const StyledEyebrow = styled(Eyebrow)`
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const StyledContent = styled.div`
  flex: 1;

  padding-left: 16px;
  padding-right: 16px;
`;

const StyledHeading = styled(H4)`
  text-transform: uppercase;
`;

const StyledText = styled(BodyLarge)`
  margin-top: 16px;
`;

const CocktailCard = ({
  image,
  title,
  introduction,
  slug,
  baseLiquor,
  ...props
}) => {
  const urlPath = `/cocktails/${slug}/`;

  return (
    <Link href={urlPath}>
      <StyledCocktailCard {...props}>
        <StyledImageWrapper>
          <StyledImage image={image.gatsbyImageData} alt={image.alt} />
        </StyledImageWrapper>
        <StyledContent>
          <StyledEyebrow>{baseLiquor.title}</StyledEyebrow>
          <StyledHeading>{title}</StyledHeading>
          <StyledText>{introduction}</StyledText>
        </StyledContent>
      </StyledCocktailCard>
    </Link>
  );
};

export default CocktailCard;

export const BaseLiquorFragment = graphql`
  fragment BaseLiquorFragment on DatoCmsBaseLiquor {
    title
  }
`;

export const CocktailCardFragment = graphql`
  fragment CocktailCardFragment on DatoCmsCocktailRecipe {
    cardImage {
      gatsbyImageData(width: 480, height: 640)
      alt
    }
    title
    introduction
    baseLiquor {
      ...BaseLiquorFragment
    }
    slug
    __typename
  }
`;
